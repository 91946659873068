import get from 'lodash/get';
import { connect } from 'react-redux';
import commonStore from '../../common/CommonStore';
import { HEADER_MENUS, LOCK_TYPES, TA_PORTAL_URL, USER_TYPES } from '../../common/Constants';
import { setViewAndShowModal } from '../../common/ModalStore';
import userStore, { verifyLockUnlockStatus } from '../../common/UserStore';
import { changeBookingFunction } from '../../common/Utils/storeHelpers';
import notificationsStore from '../../pages/notifications/NotificationsStore';
import MvjHeader from './MvjHeader';

const { getLogoUrl, getMenuItems, getBackButton, getMenuBookings, getPageTabLabels } = commonStore.selectors;
const { setOpenStartGuide } = commonStore.creators;
const { getLoggedInUser, getBookingDetails, getUserType, getIsTaAccount } = userStore.selectors;
const { getHeaderIcons } = notificationsStore.selectors;
const { getLabels } = commonStore.selectors;

const mapStateToProps = (state) => {
  let preHeaderText;
  let preHeaderLink;
  let preHeaderLinkMobile;
  const userType = getUserType(state);
  const isTaAccount = getIsTaAccount(state);

  const labels = getLabels(state);

  if ([USER_TYPES.CSA, USER_TYPES.AIR].includes(userType)) {
    preHeaderText = get(getPageTabLabels(state)(), 'labels.administrativeUser');
  } else if (isTaAccount || [USER_TYPES.TA].includes(userType)) {
    preHeaderText = get(getPageTabLabels(state)(), 'labels.TaAgentUser');
    preHeaderLink = get(getPageTabLabels(state)(), 'labels.TaAgentUserManageBooking');
    preHeaderLinkMobile = labels?.buttons?.back;
  }

  return {
    userType,
    preHeaderText,
    preHeaderLink,
    preHeaderLinkMobile,
    loggedInUserName: getLoggedInUser(state),
    icons: getHeaderIcons(state),
    logo: getLogoUrl(state),
    homeLink: getBackButton(state).previousPagePath,
    menuItems: {
      left: getMenuItems(state)(HEADER_MENUS.LEFT),
      right: getMenuItems(state)(HEADER_MENUS.RIGHT),
      bookings: getMenuBookings(state),
    },
  };
};

const addChangeBookingFunction = (bookings) => (dispatch, getState) => {
  const state = getState();
  const { bookingNumber } = getBookingDetails(state);
  return bookings.map((booking) => {
    if (booking.id === bookingNumber) {
      return booking;
    }
    return {
      ...booking,
      onClick: () => {
        dispatch(
          changeBookingFunction({
            currentBookingNumber: bookingNumber,
            newBookingNumber: booking.id,
          })
        );
      },
    };
  });
};

const mapDispatchToProps = (dispatch) => ({
  addChangeBookingFunction: (bookings) => dispatch(addChangeBookingFunction(bookings)),
  handleAddBookingOpen: () => dispatch(setViewAndShowModal('addBookingModal')),
  startGuideOpen: () => dispatch(setViewAndShowModal('mvjStartGuideWelcomeToMVJ')),
  setOpenStartGuide: (openStartGuideValue) => dispatch(setOpenStartGuide(openStartGuideValue)),
  handleTaRedirect: async () => {
    await dispatch(
      verifyLockUnlockStatus({
        type: LOCK_TYPES.UNLOCK,
      })
    );
    window.location.replace(`${TA_PORTAL_URL}/manage-bookings`);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MvjHeader);
